export const columns = [
  {
    title: '楼盘名称',
    dataIndex: 'name'
  },
  {
    title: '人脸识别系统url',
    dataIndex: 'faceUrl',
    scopedSlots: { customRender: 'faceUrl' }
  },
  {
    title: '操作',
    key: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]
