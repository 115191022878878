<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="楼盘名称">
                <a-input v-model="queryParam.keyword" placeholder="请输入楼盘名称" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="开发商名称">
                <a-input v-model="queryParam.developerName" placeholder="请输入开发商名称" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetReload">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        class="mTable"
        :rowKey="record => record.id"
        :columns="columns"
        :data="housesData"
        :alert="true"
        :totalCount="totalCount"
        :scroll="defaultScroll"
        showPagination="auto"
      >
        <span slot="developer" slot-scope="text">
          {{ text.name }}
        </span>
        <span slot="salePrice" slot-scope="text">
          <span>{{ text ? `${priceFormat(text)} 元/平` : '未设置' }}</span>
        </span>
        <span slot="faceUrl" slot-scope="text">
          <template>
            <a v-if="text" :href="text" target="_blank"><img :src="faceImg" alt="人脸识别"></a>
            <span v-else>--</span>
          </template>
        </span>

        <span v-if="btnPermission('residence_edit')" slot="action">
          <a @click="handleBaseEdit(record)">编辑</a>
          <!-- <template>
            <a-dropdown>
              <a-menu slot="overlay">
                <a-menu-item @click="handleBaseEdit(record)"><a>基础信息</a></a-menu-item>
                <a-menu-item @click="handleEdit(record)"><a>更多信息</a></a-menu-item>
              </a-menu>
              <a>编辑<a-icon type="down"/></a>
            </a-dropdown>
          </template> -->
        </span>
        <span slot="action" v-else>
          暂无权限
        </span>
      </s-table>
    </a-card>
    <house-create ref="houseCreate" @reload="reload" />
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import houseCreate from './components/houseCreate'
import { queryHouse } from '@/api/house'
import { columns } from './js/house'
import faceImg from '@/assets/img/faceImg.svg'
export default {
  components: {
    STable,
    Ellipsis,
    houseCreate
  },
  data() {
    return {
      columns,
      totalCount: 0,
      faceImg,
      queryParam: {},
      housesData: parameter => {
        const param = {
          developerId: this.userId
        }
        const params = this.paramFormat(parameter, param)
        Object.assign(params, this.queryParam)
        return queryHouse(params)
          .then(res => {
            const result = this.resFormat(res, 'area')
            this.totalCount = result.totalCount
            return result
          })
      }
    }
  },
  created() {},
  computed: {
    serial() {
      return this.$store.state.env.serial
    },
    userId() {
      return this.$store.state.user.info.id
    }
  },
  methods: {
    reload() {
      this.$refs.table.refresh()
    },
    resetReload() {
      this.queryParam = {}
      this.reload()
    },
    handleAdd() {
      this.$refs.houseCreate.show()
    },
    handleBaseEdit(record) {
      this.$refs.houseCreate.show(record)
    },
    handleEdit(record) {
      this.$router.push({
        name: 'housesEdit',
        path: '/residence/houses',
        query: {
          id: record.id,
          link: this.serial + 'rest/houses/' + record.id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .mTable {
    /deep/ .ant-table-tbody tr td:nth-child(2) {
      padding: 9px 16px;
    }
  }
</style>
