import request from '@/utils/request'

const developerApi = {
  // get developer info
  developers: '/api/v1/rest/developers',
  findByKeyword: '/api/v1/rest/developers/search/findByKeyword',
  generateQcode: '/api/v1/developer/generateQcode'
}

/**
 * list
 * @param param { page: '', size: '', sort: '' }
 * @returns {*}
 */
export function developers (param) {
  return request({
    url: developerApi.developers + `?page=${param.page}&size=${param.size}&sort=${param.sort}`,
    method: 'get'
  })
}

/**
 * add
 * @param param { id: '', name: '', code: '', area: '' }
 * @returns {*}
 */
export function addDevelopers (param) {
  return request({
    url: developerApi.developers,
    method: 'post',
    data: param
  })
}

/**
 * add
 * @param param
 * @returns {*}
 */
export function getDevelopers (id) {
  return request({
    url: developerApi.developers + '/' + id,
    method: 'get'
  })
}

/**
 * edit
 * @param param { id: '', name: '', code: '', area: '' }
 * @returns {*}
 */
export function editDevelopers (id, data) {
  return request({
    url: developerApi.developers + `/${id}`,
    method: 'patch',
    data
  })
}

/**
 * del
 * @param param { id: '' }
 * @returns {*}
 */
export function delDevelopers (param) {
  return request({
    url: developerApi.developers + `/${param.id}`,
    method: 'delete'
  })
}

export function developerGenerateQcode (param) {
  const url = request.requestFormat(developerApi.generateQcode, param)
  return request({
    url,
    method: 'get'
  })
}

/**
 * get
 * @param id
 * @returns {*}
 */
export function getDevelopersContract (id) {
  return request({
    url: developerApi.developers + '/' + id + '/contract',
    method: 'get'
  })
}

/**
 * put
 * @param id
 * @returns {*}
 */
export function putDevelopersContract (id, data) {
  return request({
    url: developerApi.developers + '/' + id + '/contract',
    method: 'put',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

/**
 * search
 * @param param { keyword: '', page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function findDevelopers (param) {
  let url = developerApi.findByKeyword
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * search
 * @param param { id: '' }
 * @returns {*}
 */
export function findByIdDevelopers (param) {
  return request({
    url: developerApi.developer + `/${param.id}`,
    method: 'get'
  })
}
